<template>
  <svg
    width="113px"
    height="44px"
    viewBox="0 0 113 44"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <rect id="path-1" x="0" y="0" width="112.92" height="43.8217822"></rect>
    </defs>
    <g
      id="Symbols"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Menu/Bedrijf" transform="translate(-12, -3)">
        <g id="Frame" transform="translate(12, 3)">
          <mask id="mask-2" fill="white">
            <use xlink:href="#path-1"></use>
          </mask>
          <g id="Frame-(Background/Mask)"></g>
          <path
            id="Vector"
            d="M11.4428724,1.08703399 C11.4428724,0.4874245 10.955448,0 10.3558384,0 L1.08703417,0 C0.487424583,0 0,0.4874245 0,1.08703399 L0,32.8121796 C0,33.4117891 0.487424583,33.8992126 1.08703417,33.8992126 L10.3558384,33.8992126 C10.955448,33.8992126 11.4428724,33.4117891 11.4428724,32.8121796 L11.4428724,16.3674059 C11.4428724,15.60919 12.0579556,14.9902377 12.8200401,14.9902377 L13.5434396,14.9902377 C14.3016557,14.9902377 14.9206081,15.6053215 14.9206081,16.3674059 L14.9206081,32.8083103 C14.9206081,33.4079198 15.4080332,33.8953463 16.0076428,33.8953463 L25.2803143,33.8953463 C25.8799239,33.8953463 26.3673505,33.4079198 26.3673505,32.8083103 L26.3673505,12.8200379 C26.3673505,11.2185003 25.0714178,9.92257011 23.46988,9.92257011 L12.5337757,9.92257011 C11.9341661,9.92257011 11.4467409,9.43514579 11.4467409,8.8355363 L11.4428724,8.8355363 L11.4428724,1.08703399 Z"
            fill="#4A154B"
            fill-rule="nonzero"
            mask="url(#mask-2)"
          ></path>
          <path
            id="Vector"
            d="M112.920141,11.0095868 C112.920141,10.4099774 112.432717,9.9225531 111.833108,9.9225531 L102.560437,9.9225531 C101.960828,9.9225531 101.473404,10.4099774 101.473404,11.0095868 L101.473404,27.4504903 C101.473404,28.2087061 100.858322,28.8276569 100.096238,28.8276569 L99.3728398,28.8276569 C98.614624,28.8276569 97.9956732,28.2125746 97.9956732,27.4504903 L97.9956732,11.0095868 C97.9956732,10.4099774 97.5082438,9.9225531 96.9086343,9.9225531 L87.6359636,9.9225531 C87.0363542,9.9225531 86.5489307,10.4099774 86.5489307,11.0095868 L86.5489307,31.0017257 C86.5489307,32.6032632 87.844863,33.8991925 89.4464005,33.8991925 L110.014944,33.8991925 C111.616482,33.8991925 112.912408,32.6032632 112.912408,31.0017257 L112.920141,31.0017257 L112.920141,11.0095868 Z"
            fill="#4A154B"
            fill-rule="nonzero"
            mask="url(#mask-2)"
          ></path>
          <path
            id="Vector"
            d="M51.6169124,17.9302898 C53.2107132,17.7987625 54.3944646,16.3983841 54.2629373,14.8007147 L53.6362446,7.29592526 C53.4815067,5.42359598 51.8335461,4.03095401 49.9612168,4.18569197 L30.7621072,5.78722982 C29.1683064,5.91875708 27.9845625,7.31913537 28.1160897,8.91680477 L29.6286516,27.0327494 C29.7601789,28.6265502 31.160559,29.8102986 32.7582284,29.6787713 L54.2668036,27.8838099 C54.8664132,27.83352 55.3074192,27.3074114 55.2609978,26.7116703 L54.8625481,21.9496093 C54.8122582,21.3499998 54.2861437,20.9089953 53.6904026,20.9554166 L41.92645,21.938003 C41.2494715,21.9960297 40.6537324,21.489263 40.5957057,20.8122845 L40.5338091,20.0888849 C40.4757823,19.4119064 40.9825505,18.8161658 41.6595291,18.7581391 L41.6556598,18.7620069 L51.6169124,17.9302898 Z M39.7639893,11.3577961 C39.7059626,10.6808176 40.2127271,10.0850766 40.8897056,10.0270499 L43.3423037,9.82202185 C44.0192822,9.76399511 44.6150243,10.2707615 44.673051,10.94774 L44.7349447,11.6711403 C44.7929714,12.3481188 44.2862061,12.9438601 43.6092276,13.0018869 L41.1566296,13.2069142 C40.479651,13.2649409 39.8839097,12.7581742 39.825883,12.0811956 L39.7639893,11.3577961 Z"
            fill="#4A154B"
            mask="url(#mask-2)"
          ></path>
          <path
            id="Vector"
            d="M72.3594187,42.7308619 C72.3594187,43.3304713 72.8468423,43.8178949 73.4464517,43.8178949 L82.7191224,43.8178949 C83.3187319,43.8178949 83.8061554,43.3304713 83.8061554,42.7308619 L83.8061554,11.0095869 C83.8061554,10.4099774 83.3187319,9.9225531 82.7191224,9.9225531 L73.4464517,9.9225531 C72.8468423,9.9225531 72.3594187,10.4099774 72.3594187,11.0095869 L72.3594187,27.4504912 C72.3594187,28.2087071 71.7443364,28.8276579 70.9822521,28.8276579 L70.2588483,28.8276579 C69.5006325,28.8276579 68.8816817,28.2125756 68.8816817,27.4504912 L68.8816817,11.0095869 C68.8816817,10.4099774 68.3942582,9.9225531 67.7946487,9.9225531 L58.5219781,9.9225531 C57.9223686,9.9225531 57.4349451,10.4099774 57.4349451,11.0095869 L57.4349451,31.0017269 C57.4349451,32.6032644 58.7308774,33.8991938 60.3324149,33.8991938 L71.2685135,33.8991938 C71.868123,33.8991938 72.3555465,34.3866203 72.3555465,34.9862298 L72.3594187,34.9823605 L72.3594187,42.7308619 Z"
            fill="#4A154B"
            fill-rule="nonzero"
            mask="url(#mask-2)"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
