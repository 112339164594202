export function useApiMessage() {
  const client = useStrapiClient()
  const user = useStrapiUser()

  async function getOverview(search: string = '') {
    const type = user.value?.type
    let filters = {}

    if (search) {
      if (type === 'company') {
        filters = {
          $or: [
            {
              professional: {
                user: {
                  contact: {
                    first_name: { $containsi: search },
                  },
                },
              },
            },
            {
              professional: {
                user: {
                  contact: {
                    last_name: { $containsi: search },
                  },
                },
              },
            },
          ],
        }
      } else {
        filters = {
          company: { name: { $containsi: search } },
        }
      }
    }

    return await client('/messages/my/overview', {
      method: 'GET',
      params: {
        filters,
      },
    })
  }

  async function getReceivedCommunications() {
    return await client('/received-communications/my/', {
      method: 'GET',
    })
  }

  async function getConversation(id: number) {
    return await client(`/messages/my/conversation/${id}`, {
      method: 'GET',
    })
  }

  async function sendMessage(id: number, body: any) {
    return await client(`/messages/send/${id}`, {
      method: 'POST',
      body,
    })
  }

  async function setMessageRead(id: number) {
    return await client(`/messages/my/${id}/read`, {
      method: 'POST',
    })
  }

  return {
    getOverview,
    getReceivedCommunications,
    getConversation,
    sendMessage,
    setMessageRead,
  }
}
